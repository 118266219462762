


























































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ISideBar, IUser } from '@/types/types';

const auth = namespace('auth');
@Component
export default class SideBar extends Vue {
  @Prop({ type: Boolean, default: true }) drawer!: boolean;
  @Prop({ type: String, default: 'home' }) navigationKey!: string;
  @Prop({ type: Array, default: () => [], required: true }) sideBarItems!: Array<ISideBar | { label: string }>;
  @auth.Getter('getUserDetails') user!: IUser;
  closer: boolean = this.drawer;
  menu: boolean = false;

  logOut() {
    this.$store.dispatch('auth/logOut');
  }

  goBack() {
    this.$store.dispatch('navigation/setAppLinks', 'home');
    this.$router.replace({
      name: 'dashboard',
    });
  }

  created(): void {}
}
